@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #herosection {
    background-image: url(/public/quality-cars-inicio.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    position: relative;
  }

  #trusted {
    background-image: url(/public/mercedes_benz.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    position: relative;
  }